import React from 'react'
import PropTypes from 'prop-types'

import Text from './Text'
import { Link } from 'gatsby'

import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import { color } from '../../style/theme'

const Button = props => {
  const { children, href, white, ...rest } = props

  return href ? (
    <StyledLink color={color} to={href} white={white} {...rest}>
      <Text semiBold color={white ? color.coral : '#fff'} center>
        {children}
      </Text>
    </StyledLink>
  ) : (
    <StyledButton white={white} {...rest}>
      <Text
        size="sm"
        nowrap
        semiBold
        color={white ? color.coral : '#fff'}
        center
      >
        {children}
      </Text>
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.node,
}

export default Button

export const buttonStyle = ({ theme, white }) => css`
  background-color: ${white ? '#fff' : theme.color.coral};
  border-radius: 100px;
  font-size: 14px;
  color: ${white ? theme.color.coral : '#fff'};
  letter-spacing: 0.78px;
  text-align: center;
  min-height: 40px;
  padding: 0 ${modularScale(2)};
  max-width: 220px;
  transition: background-color 0.4s ease-in;
  cursor: pointer;

  &:hover {
    transition: background-color 0.3s ease-out;
    background-color: #f0877f;
  }
`

const StyledLink = styled(Link)`
  ${buttonStyle};
  overflow: hidden;
`

const StyledButton = styled.button`
  border: none;
  outline: none;
  ${buttonStyle};
  overflow: hidden;
`
