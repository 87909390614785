import React from 'react'

import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import mq from '../../style/mediaQueries'

const SIZE = 24

const PrevNext = ({ color, index, length, onNext, onPrev, ...props }) => {
  return (
    <Container>
      <Button disabled={index === 0} onClick={onPrev} prev {...props}>
        <svg width={SIZE} height={SIZE} viewBox="0 0 50 50">
          <path
            d="M15.95 49.45l-4.24-4.24L31.93 25 11.71 4.79 15.95.55 40.41 25 15.95 49.45z"
            fill={color}
          />
        </svg>
      </Button>
      <Button disabled={index >= length - 1} onClick={onNext} {...props}>
        <svg width={SIZE} height={SIZE} viewBox="0 0 50 50">
          <path
            d="M15.95 49.45l-4.24-4.24L31.93 25 11.71 4.79 15.95.55 40.41 25 15.95 49.45z"
            fill={color}
          />
        </svg>
      </Button>
    </Container>
  )
}

export default PrevNext

const Container = styled.div`
  position: absolute;
  bottom: -10px;
  right: 0;

  ${({ theme }) =>
    theme.device.mobile &&
    css`
      display: none;
    `};

  ${mq.md`
    display: block;
    `};
`

const Button = styled.button`
  ${({ disabled, prev, theme }) => css`
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    ${theme.mixin.hover};
    margin-left: ${modularScale(0)};

    ${prev &&
      css`
        transform: scale(-1, 1);
      `};

    ${disabled &&
      css`
        opacity: 0.3;
        potiner-events: none;
        cursor: auto;

        &:hover {
          opacity: 0.3;
        }
      `};
  `};
`
