import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { useSpring, animated } from 'react-spring'
import mq from '../../style/mediaQueries'

const config = { mass: 1, tension: 120, friction: 20 }

const ModalBoxForm = ({ children, fullscreen, hide, small }) => {
  const styles = useSpring({
    reverse: hide,
    from: {
      opacity: 0,
      height: '0%',
    },
    height: '100%',
    opacity: 1,
    config: config.gentle,
  })

  return (
    <Box hide={hide} fullscreen={fullscreen} small={small} style={styles}>
      {children}
    </Box>
  )
}

ModalBoxForm.propTypes = {
  children: PropTypes.node,
  fullscreen: PropTypes.bool,
  show: PropTypes.bool,
  small: PropTypes.bool,
}

export default ModalBoxForm

const Box = styled(animated.div)`
  ${({ fullscreen, small }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;

    ${mq.md`
        align-items: center;
    `};

    ${!fullscreen &&
      small &&
      css`
        max-width: 500px;
      `};
  `};
`
