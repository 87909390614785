import { useEffect, useState } from 'react'

import loadImage from 'image-promise'

const useLoadImages = images => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (images && images.length) {
      loadImage(images)
        .then(allImgs => {
          setLoaded(true)
        })
        .catch(err => {
          console.error('One or more images have failed to load :(')
          console.error(err.errored)
          console.info('But these loaded fine:')
          console.info(err.loaded)
        })
    }
  }, [images])

  return loaded
}

export default useLoadImages
