import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Text from './Text'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'

import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import mq from '../../style/mediaQueries'
import { get } from 'lodash'

export function transform(node, index) {
  const { type, name } = node
  if (type === 'tag' && (name === 'h1' || name === 'h3' || name === 'p')) {
    return convertNodeToElement({ ...node, name: 'h2' }, index, transform)
  }
}

const TextBlock = ({ body, headline, hideLine, last, white }) => {
  const bodyText = get(body, 'childMarkdownRemark.html')
  const headlineText = get(headline, 'childMarkdownRemark.html')
  return !!bodyText ? (
    <Container last={last}>
      {!!headlineText && (
        <RichTitle size={18} md={22} lg="lg" html white={white}>
          {ReactHtmlParser(headlineText, { transform })}
        </RichTitle>
      )}
      <Row>
        {!hideLine && <Line />}
        <Text size="sm" lg="md" html white={white}>
          {ReactHtmlParser(bodyText)}
        </Text>
      </Row>
    </Container>
  ) : null
}

TextBlock.propTypes = {
  body: PropTypes.object,
  headline: PropTypes.object,
  hideLine: PropTypes.bool,
  last: PropTypes.bool,
  white: PropTypes.bool,
}

export default memo(TextBlock)

const RichTitle = styled(Text)`
  margin-bottom: ${modularScale(1)};
`

const Container = styled.div`
  ${({ last }) =>
    !last &&
    css`
      margin-bottom: ${modularScale(6)};
    `};

  > h2 {
    margin-bottom: ${modularScale(1)};
  }
`

const Row = styled.div`
  display: flex;
`

const Line = styled.div`
  position: relative;

  margin-right: 10vw;

  ${mq.lg`
    margin-right: ${modularScale(5)};
  `};

  &:before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.color.blueLight2};
    width: 3px;
    height: 100%;
  }
`
