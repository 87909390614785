import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PrevNext from './PrevNext'

import styled, { css } from 'styled-components'
import { modularScale, transparentize } from 'polished'
import { throttle } from 'lodash'

class FlickityContainer extends Component {
  static propTypes = {
    dotColor: PropTypes.string,
    children: PropTypes.node,
    loaded: PropTypes.bool,
    flickityRef: PropTypes.func,
    minHeight: PropTypes.number,
    options: PropTypes.object,
    hidePrevNext: PropTypes.bool,
  }

  static defaultProps = {
    dotColor: '#fff',
  }

  constructor(props) {
    super(props)

    this._options = {
      groupCells: true,
      contain: true,
      accessibility: false,
      pageDots: true,
      prevNextButtons: false,
      imagesLoaded: true,
      ...props.options,
    }

    this._resize = throttle(this.resize, 100)
  }

  state = {
    index: 0,
  }

  _Flickity

  componentDidMount() {
    this._loadFlickity()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loaded && this.props.loaded) {
      this._flkty.resize()
    }
  }

  componentWillUnmount() {
    if (this._flkty) {
      if (window !== 'undefined') {
        window.removeEventListener('resize', this._resize)
      }

      this._flkty.destroy()
      this._flkty = null
    }
  }

  _loadFlickity = () => {
    if (!this._Flickity && typeof window !== 'undefined')
      this._Flickity = require('flickity-fade')
    if (this._Flickity && typeof window !== 'undefined') {
      const { flickityRef } = this.props
      this._flkty = new this._Flickity(this._el, this._options)
      this._flkty.on('settle', this._onSelected)
      this._flkty.resize()

      window.addEventListener('resize', this._resize, false)

      if (flickityRef) flickityRef(this._flkty)
    }
  }

  get flickity() {
    return this._flkty
  }

  get index() {
    return this._flkty ? this._flkty.selectedIndex : this.state.index
  }

  _onSelected = () => this.setState({ index: this.index })

  _prev = () => this._flkty.previous()

  _next = () => this._flkty.next()

  resize = () => this._flkty.resize()

  // _onPrevious = currentIndex => {
  //   const { length } = this.props
  //   const { index: currentIndex } = this.state
  //   const index = (currentIndex - 1 + length) % length
  //   this.setState({ index })
  // }

  // _onNext = currentIndex => {
  //   const { length } = this.props
  //   const { index: currentIndex } = this.state
  //   const index = (currentIndex + 1) % length
  //   this.setState({ index })
  // }

  render() {
    const { children, dotColor, hidePrevNext, length, minHeight } = this.props
    const { index } = this.state
    return (
      <>
        <Container
          ref={i => (this._el = i)}
          dotColor={dotColor}
          center={hidePrevNext}
          minHeight={minHeight}
        >
          {children}
        </Container>
        {!hidePrevNext && (
          <PrevNext
            color={dotColor}
            index={index}
            length={length}
            onNext={this._next}
            onPrev={this._prev}
          />
        )}
      </>
    )
  }
}

export default FlickityContainer

const Container = styled.div`
  ${({ center, minHeight, dotColor }) => css`
    position: relative;

    .flickity-page-dots {
      display: flex;

      bottom: 0px;

      ${center &&
        css`
          justify-content: center;
        `};

      .dot {
        width: 30px;
        height: 10px;
        opacity: 1;
        background-color: ${transparentize(0.4, dotColor)};
        border-radius: 5px;
        margin: ${modularScale(2)} ${modularScale(-4)} 0;
        cursor: pointer;
        transition: background-color 0.4s ease-in;

        &:hover {
          background-color: ${transparentize(0.7, dotColor)};
          transition: background-color 0.3s ease-out;
        }
      }

      .dot.is-selected {
        background-color: ${transparentize(0, dotColor)};
        border: none;
        cursor: auto;
      }
    }

    ${minHeight &&
      css`
        > div {
          min-height: ${minHeight}px;
        }
      `};
  `};
`
