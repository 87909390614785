import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { color } from '../../style/theme'
import { everyFrame } from 'popmotion'

const XMLNS = 'http://www.w3.org/2000/svg'
const ID = 1
const noDoc = typeof document === 'undefined'

export class LiquidButton extends PureComponent {
  static propTypes = {
    color1: PropTypes.string,
    color2: PropTypes.string,
    color3: PropTypes.string,
    debug: PropTypes.bool,
    forceFactor: PropTypes.number,
    gap: PropTypes.number,
    height: PropTypes.number,
    hoverFactor: PropTypes.number,
    margin: PropTypes.number,
    layerViscosity0: PropTypes.number,
    layerViscosity1: PropTypes.number,
    layerMouseForce0: PropTypes.number,
    layerMouseForce1: PropTypes.number,
    layerForceLimit0: PropTypes.number,
    layerForceLimit1: PropTypes.number,
    tension: PropTypes.number,
    text: PropTypes.string,
    textColor: PropTypes.string,
    width: PropTypes.number,
  }

  static defaultProps = {
    color1: color.blueLight,
    color2: color.coral,
    color3: color.blue,
    debug: false,
    forceFactor: 0.1,
    gap: 5,
    height: 45,
    hoverFactor: -0.1,
    margin: 20,
    layerViscosity0: 0.5,
    layerViscosity1: 0.4,
    layerMouseForce0: 400,
    layerMouseForce1: 500,
    layerForceLimit0: 1,
    layerForceLimit1: 2,
    tension: 0.4,
    text: 'Let’s work together',
    textColor: '#FFFFFF',
    width: 180,
  }

  componentDidMount() {
    if (noDoc) return

    const { height } = this.props

    this.layers = [{ points: [] }, { points: [] }]

    for (let layerIndex = 0; layerIndex < this.layers.length; layerIndex++) {
      const layer = this.layers[layerIndex]
      layer.viscosity = this.props[`layerViscosity${layerIndex}`]
      layer.mouseForce = this.props[`layerMouseForce${layerIndex}`]
      layer.forceLimit = this.props[`layerForceLimit${layerIndex}`]
      layer.path = document.createElementNS(XMLNS, 'path')
      this.svg.appendChild(layer.path)
    }

    this.wrapperElement = this.props.wrapperElement || document.body

    if (!this.svg.parentElement) this.wrapperElement.append(this.svg)

    this.svgText = document.createElementNS(XMLNS, 'text')
    this.svgText.setAttribute('x', '50%')
    this.svgText.setAttribute('y', '50%')
    this.svgText.setAttribute('dy', ~~(height / 8) + 'px')
    this.svgText.setAttribute('font-size', ~~(height / 3))
    this.svgText.style.fontFamily = 'SharpSansNo1'
    this.svgText.setAttribute('text-anchor', 'middle')
    this.svgText.setAttribute('pointer-events', 'none')
    this.svg.appendChild(this.svgText)

    this.svgDefs = document.createElementNS(XMLNS, 'defs')
    this.svg.appendChild(this.svgDefs)

    this.touches = []
    this.noise = this.props.noise || 0
    this.svg.addEventListener('touchstart', this.touchHandler, false)
    this.svg.addEventListener('touchmove', this.touchHandler, false)
    this.svg.addEventListener('touchend', this.clearHandler, false)
    this.svg.addEventListener('touchcancel', this.clearHandler, false)
    this.svg.addEventListener('mousemove', this.mouseHandler, false)
    this.svg.addEventListener('mouseout', this.clearHandler, false)
    this.initOrigins()

    // start
    this.raf = everyFrame().start(this.animate)
  }

  componentWillUnmount() {
    if (noDoc) return
    this.svg.removeEventListener('touchstart', this.touchHandler)
    this.svg.removeEventListener('touchmove', this.touchHandler)
    this.svg.removeEventListener('touchend', this.clearHandler)
    this.svg.removeEventListener('touchcancel', this.clearHandler)
    this.svg.removeEventListener('mousemove', this.mouseHandler)
    this.svg.removeEventListener('mouseout', this.clearHandler)

    if (this.raf) {
      this.raf.stop()
      this.raf = null
    }
  }

  get mouseHandler() {
    return e => {
      this.touches = [
        {
          x: e.offsetX,
          y: e.offsetY,
          force: 1,
        },
      ]
    }
  }

  get touchHandler() {
    if (!!this.svg && !noDoc) {
      return e => {
        this.touches = []
        const rect = this.svg.getBoundingClientRect()
        for (
          let touchIndex = 0;
          touchIndex < e.changedTouches.length;
          touchIndex++
        ) {
          const touch = e.changedTouches[touchIndex]
          const x = touch.pageX - rect.left
          const y = touch.pageY - rect.top
          if (x > 0 && y > 0 && x < this.svgWidth && y < this.svgHeight) {
            this.touches.push({ x, y, force: touch.force || 1 })
          }
        }
        e.preventDefault()
      }
    }
    return null
  }

  get clearHandler() {
    return e => (this.touches = [])
  }

  distance = (p1, p2) => {
    return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2))
  }

  update = () => {
    const {
      forceFactor,
      height,
      hoverFactor,
      margin,
      tension,
      width,
    } = this.props

    for (let layerIndex = 0; layerIndex < this.layers.length; layerIndex++) {
      const layer = this.layers[layerIndex]
      const points = layer.points
      for (let pointIndex = 0; pointIndex < points.length; pointIndex++) {
        const point = points[pointIndex]
        const dx = point.ox - point.x + (Math.random() - 0.5) * this.noise
        const dy = point.oy - point.y + (Math.random() - 0.5) * this.noise
        const d = Math.sqrt(dx * dx + dy * dy)
        const f = d * forceFactor
        point.vx += f * (dx / d || 0)
        point.vy += f * (dy / d || 0)
        for (
          let touchIndex = 0;
          touchIndex < this.touches.length;
          touchIndex++
        ) {
          const touch = this.touches[touchIndex]
          let mouseForce = layer.mouseForce
          if (
            touch.x > margin &&
            touch.x < margin + width &&
            touch.y > margin &&
            touch.y < margin + height
          ) {
            mouseForce *= -hoverFactor
          }
          const mx = point.x - touch.x
          const my = point.y - touch.y
          const md = Math.sqrt(mx * mx + my * my)
          const mf = Math.max(
            -layer.forceLimit,
            Math.min(layer.forceLimit, (mouseForce * touch.force) / md)
          )
          point.vx += mf * (mx / md || 0)
          point.vy += mf * (my / md || 0)
        }
        point.vx *= layer.viscosity
        point.vy *= layer.viscosity
        point.x += point.vx
        point.y += point.vy
      }
      for (let pointIndex = 0; pointIndex < points.length; pointIndex++) {
        const prev = points[(pointIndex + points.length - 1) % points.length]
        const point = points[pointIndex]
        const next = points[(pointIndex + points.length + 1) % points.length]
        const dPrev = this.distance(point, prev)
        const dNext = this.distance(point, next)

        const line = {
          x: next.x - prev.x,
          y: next.y - prev.y,
        }
        const dLine = Math.sqrt(line.x * line.x + line.y * line.y)

        point.cPrev = {
          x: point.x - (line.x / dLine) * dPrev * tension,
          y: point.y - (line.y / dLine) * dPrev * tension,
        }
        point.cNext = {
          x: point.x + (line.x / dLine) * dNext * tension,
          y: point.y + (line.y / dLine) * dNext * tension,
        }
      }
    }
  }

  animate = () => {
    this.update()
    this.draw()
  }

  get svgWidth() {
    return this.props.width + this.props.margin * 2
  }

  get svgHeight() {
    return this.props.height + this.props.margin * 2
  }

  draw = () => {
    const { color1, color2, color3, text, textColor, width } = this.props

    for (let layerIndex = 0; layerIndex < this.layers.length; layerIndex++) {
      const layer = this.layers[layerIndex]
      if (layerIndex === 1) {
        if (this.touches.length > 0) {
          while (this.svgDefs.firstChild) {
            this.svgDefs.removeChild(this.svgDefs.firstChild)
          }
          for (
            let touchIndex = 0;
            touchIndex < this.touches.length;
            touchIndex++
          ) {
            const touch = this.touches[touchIndex]
            const gradient = document.createElementNS(XMLNS, 'radialGradient')
            gradient.id = `liquid-gradient-${ID}-${touchIndex}`
            const start = document.createElementNS(XMLNS, 'stop')
            start.setAttribute('stop-color', color3)
            start.setAttribute('offset', '0%')
            const stop = document.createElementNS(XMLNS, 'stop')
            stop.setAttribute('stop-color', color2)
            stop.setAttribute('offset', '100%')
            gradient.appendChild(start)
            gradient.appendChild(stop)
            this.svgDefs.appendChild(gradient)
            gradient.setAttribute('cx', touch.x / this.svgWidth)
            gradient.setAttribute('cy', touch.y / this.svgHeight)
            gradient.setAttribute('r', touch.force)
            layer.path.style.fill = `url(#${gradient.id})`
          }
        } else {
          layer.path.style.fill = color2
        }
      } else {
        layer.path.style.fill = color1
      }
      const points = layer.points
      const commands = []
      commands.push('M', points[0].x, points[0].y)
      for (let pointIndex = 1; pointIndex < points.length; pointIndex += 1) {
        commands.push(
          'C',
          points[(pointIndex + 0) % points.length].cNext.x,
          points[(pointIndex + 0) % points.length].cNext.y,
          points[(pointIndex + 1) % points.length].cPrev.x,
          points[(pointIndex + 1) % points.length].cPrev.y,
          points[(pointIndex + 1) % points.length].x,
          points[(pointIndex + 1) % points.length].y
        )
      }
      commands.push('Z')
      layer.path.setAttribute('d', commands.join(' '))
    }
    this.svgText.textContent = text
    this.svgText.style.fill = textColor
  }

  createPoint = (x, y) => {
    return {
      x: x,
      y: y,
      ox: x,
      oy: y,
      vx: 0,
      vy: 0,
    }
  }

  initOrigins = () => {
    const { gap, height, margin, width } = this.props

    this.svg.setAttribute('width', this.svgWidth)
    this.svg.setAttribute('height', this.svgHeight)
    for (let layerIndex = 0; layerIndex < this.layers.length; layerIndex++) {
      const layer = this.layers[layerIndex]
      const points = []
      for (let x = ~~(height / 2); x < width - ~~(height / 2); x += gap) {
        points.push(this.createPoint(x + margin, margin))
      }
      for (let alpha = ~~(height * 1.25); alpha >= 0; alpha -= gap) {
        const angle = (Math.PI / ~~(height * 1.25)) * alpha
        points.push(
          this.createPoint(
            (Math.sin(angle) * height) / 2 + margin + width - height / 2,
            (Math.cos(angle) * height) / 2 + margin + height / 2
          )
        )
      }
      for (let x = width - ~~(height / 2) - 1; x >= ~~(height / 2); x -= gap) {
        points.push(this.createPoint(x + margin, margin + height))
      }
      for (let alpha = 0; alpha <= ~~(height * 1.25); alpha += gap) {
        const angle = (Math.PI / ~~(height * 1.25)) * alpha
        points.push(
          this.createPoint(
            height - (Math.sin(angle) * height) / 2 + margin - height / 2,
            (Math.cos(angle) * height) / 2 + margin + height / 2
          )
        )
      }
      layer.points = points
    }
  }

  render() {
    return <Svg ref={i => (this.svg = i)} />
  }
}

export default LiquidButton

const Svg = styled.svg`
  font-weight: 600;

  path {
    cursor: pointer;
  }
`
