import React from 'react'
import PropTypes from 'prop-types'

import Img from 'gatsby-image'
import FadeInView from '../common/FadeInView'
import Text from '../common/Text'
import ReactHtmlParser from 'react-html-parser'
import { Row, Col } from 'react-flexa'
import { Parallax } from 'react-scroll-parallax'

import { breakpointsPx } from '../../style/theme'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import mq from '../../style/mediaQueries'
import { get } from 'lodash'
import { useMedia } from 'use-media'
import { transform } from '../common/TextBlock'

const TextBlockWithImage = ({
  body,
  headline,
  hideLine,
  last,
  title,
  white,
  image,
}) => {
  const bodyText = get(body, 'childMarkdownRemark.html')
  const headlineText = get(headline, 'childMarkdownRemark.html')
  const isXL = useMedia({ minWidth: breakpointsPx.xl })

  return !!bodyText ? (
    <Parallax y={[-10, 5]}>
      <Container last={last}>
        <FadeInView>
          {!!title && !headlineText && (
            <Text.h2 size={18} md={22} lg="lg" white={white}>
              {title}
            </Text.h2>
          )}
          {!!headlineText && (
            <RichTitle size={18} md={22} lg="lg" html white={white}>
              {ReactHtmlParser(headlineText, { transform })}
            </RichTitle>
          )}
        </FadeInView>

        <Row>
          <Col sm={12} md={12} lg={12} xl={5.5}>
            <FadeInView>
              <Flex>
                {!hideLine && <Line />}
                <Text size="sm" lg="md" html white={white}>
                  {ReactHtmlParser(bodyText)}
                </Text>
              </Flex>
            </FadeInView>
          </Col>
          <Col md="hidden" lg="hidden" xl={1} />
          <Col sm={12} md={12} lg={12} xl={5.5}>
            <FadeInView>
              <Parallax y={[-20, 35]} disabled={!isXL}>
                <Img fluid={image.fluid} />
              </Parallax>
            </FadeInView>
          </Col>
        </Row>
      </Container>
    </Parallax>
  ) : null
}

TextBlockWithImage.propTypes = {
  body: PropTypes.object,
  headline: PropTypes.object,
  hideLine: PropTypes.bool,
  last: PropTypes.bool,
  title: PropTypes.string,
  white: PropTypes.bool,
}

export default TextBlockWithImage

const RichTitle = styled(Text)`
  margin-bottom: ${modularScale(1)};
`

const Container = styled.div`
  ${({ last }) =>
    !last &&
    css`
      margin-bottom: ${modularScale(6)};
    `};

  > h2 {
    margin-bottom: ${modularScale(1)};
  }

  img {
    margin-top: ${modularScale(6)};

    ${mq.xl`
        margin-top: 0;
    `};
  }
`

const Flex = styled.div`
  display: flex;
`

const Line = styled.div`
  position: relative;

  margin-right: 10vw;

  ${mq.lg`
    margin-right: ${modularScale(5)};
  `};

  &:before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.color.blueLight2};
    width: 3px;
    height: 100%;
  }
`
