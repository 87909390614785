import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'

import Button from '../common/Button'
import ErrorBoundary from '../common/ErrorBoundary'
import LiquidButton from '../common/LiquidButton'
import ModalForm from '../common/ModalForm'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import Section from '../common/Section'
import Text, { setFontSize, sizes } from '../common/Text'

import { config, useSpring, animated } from 'react-spring'
import { useMedia } from 'use-media'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import mq from '../../style/mediaQueries'
import { get } from 'lodash'
import { useInView } from 'react-intersection-observer'
import { device } from '../../style/theme'
import waves from '../../assets/waves-connect.svg'
import circ from '../../assets/circle.svg'

function transformText(node, index) {
  const { type, name } = node
  if (type === 'tag' && (name === 'h1' || name === 'h3' || name === 'p')) {
    return convertNodeToElement({ ...node, name: 'h2' }, index, transformText)
  }
}

const defaultScale = 'scale(5, 5)'

const Connect = ({
  buttonLabel,
  form,
  show,
  setActiveSection,
  setShowModal,
  slug,
  text: { body, headline, title },
}) => {
  const [ref, inView] = useInView({ threshold: 0.2, triggerOnce: true })
  const [animationDone, setAnimationDone] = useState(device.mobile)
  const bodyText = get(body, 'childMarkdownRemark.html')
  const headlineText = get(headline, 'childMarkdownRemark.html')
  const isBig = useMedia({ minWidth: 375 })

  const openModal = () => setShowModal(true)
  const closeModal = () => setShowModal(false)

  const animateIn = device.mobile || inView

  const { opacity, transform } = useSpring({
    opacity: animateIn ? 1 : 0,
    transform: animateIn ? 'scale(1, 1)' : defaultScale,
    from: { transform: defaultScale, opacity: 2 },
    config: config.slow,
    onRest: () => setAnimationDone(inView),
    config: device.mobile ? { duration: 0 } : config.default,
    delay: device.mobile ? 0 : 300,
  })

  return (
    <ErrorBoundary label="Connect">
      <Section
        id={slug}
        backgroundColor="white"
        center
        hidden
        setActiveSection={setActiveSection}
      >
        <Waves src={waves} alt="Waves" />
        <Content ref={ref}>
          <animated.div style={{ transform }}>
            <Box>
              {animationDone && <Circle src={circ} />}
              <Center offsetY={isBig} style={{ opacity }}>
                {!!title && !headlineText && (
                  <Text.h2 size={18} sm="md" md={22} lg="lg" center white>
                    {title}
                  </Text.h2>
                )}
                {!!headlineText && (
                  <Text size={18} md={22} lg="lg" center html white>
                    {ReactHtmlParser(headlineText, {
                      transform: transformText,
                    })}
                  </Text>
                )}
                <Text size="sm" lg="md" html center white>
                  {ReactHtmlParser(bodyText)}
                </Text>

                {!!isBig && inView && (
                  <ButtonContainer onClick={openModal}>
                    {device.mobile ? (
                      <Button white>{buttonLabel}</Button>
                    ) : (
                      <LiquidButton
                        color="#D3E9F2"
                        color2="#ffffff"
                        color3="#EDF3F4"
                        textColor="#F37268"
                        text={buttonLabel}
                      />
                    )}
                  </ButtonContainer>
                )}
              </Center>
            </Box>
            {!isBig && (
              <ButtonContainer onClick={openModal}>
                <Button>{buttonLabel}</Button>
              </ButtonContainer>
            )}
          </animated.div>
        </Content>
      </Section>
      <ModalForm
        small
        show={show}
        hideModal={closeModal}
        onClose={closeModal}
        form={form}
      />
    </ErrorBoundary>
  )
}

Connect.propTypes = {
  slug: PropTypes.string,
  text: PropTypes.object,
}

export default memo(Connect)

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 99999;
  pointer-events: all;
  cursor: pointer;

  button {
    margin-top: 20px;
  }

  ${({ theme }) =>
    theme.device.mobile &&
    css`
      &:after {
        position: absolute;
        content: '';
        top: 0%;
        left: 20%;
        width: 60%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
      }
    `};

  ${mq.sm`
    margin-top: ${modularScale(-3)};
  `};

  ${mq.md`
    margin-top: ${modularScale(2)};
  `};
`

const Content = styled.div`
  ${({ theme: { layout } }) => css`
    width: 100%;
    padding: ${modularScale(4)} 0;
    min-height: 600px;
  `};
`

const Box = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95vw;
    height: 95vw;
    max-width: 700px;
    max-height: 700px;
    padding: 15%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0) scale(1, 1);
    background: ${theme.color.coral};
    border-radius: 50%;

    ${mq.sm`
      padding: 12%;
    `};

    ${mq.md`
      width: 80vw;
      height: 80vw;
      padding: 100px;
      transform: translate(-50%, 0) scale(1, 1);
    `};

    h2 {
      margin-bottom: ${modularScale(0)};

      ${setFontSize(20)};

      ${mq.md`
        ${setFontSize(22)};
      `};

      ${mq.lg`
        ${setFontSize(sizes.lg)};
      `};
    }
  `};
`

const Center = styled(animated.div)`
  transform: translateY(15px);
  pointer-events: all;
  user-select: all;
  z-index: 2;

  ${({ offsetY }) =>
    offsetY &&
    css`
      transform: translateY(30px);
    `};
`

const Circle = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  user-select: none;
  pointer-events: none;
  z-index: 1;

  ${({ theme }) => theme.mixin.fadeIn(1)};
`

const Waves = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.5, 2);
  user-select: none;
  pointer-events: none;

  ${mq.md`
    transform: translate(-50%, -50%) scale(1, 1);
  `};

  ${mq.xxl`
    transform: translate(-50%, -50%) scale(1, 0.7);
  `};
`
