import React, { useState } from 'react'

import About from '../components/about/About'
import Bio from '../components/bio/Bio'
import Connect from '../components/connect/Connect'
import ConnectButton from '../components/ConnectButton'
import Events from '../components/events/Events'
import ErrorBoundary from '../components/common/ErrorBoundary'
import How from '../components/how/How'
import Layout from '../components/Layout'
import Landing from '../components/landing/Landing'
import Social from '../components/social/Social'
import SocialLinks from '../components/common/SocialLinks'
import ScrollManager from '../components/common/ScrollManager'

import { graphql } from 'gatsby'
import { get, sortBy } from 'lodash'

let lastSection

const IndexPage = ({ data, location: { hash } }) => {
  const [activeSection, setActiveSection] = useState('/')

  const handleActiveSection = section => {
    if (section !== lastSection) {
      lastSection = section
      setActiveSection(section)
    }
  }

  const [showConnectModal, setShowConnectModal] = useState(false)
  const getSectionData = id => {
    return get(data, `allContentful${id}.edges[0].node`)
  }

  const getListData = id => {
    const list = get(data, `allContentful${id}.edges`).map(item => item.node)
    return sortBy(list, 'date')
  }

  const about = getSectionData('About')
  const bio = getSectionData('Bio')
  const connect = getSectionData('Connect')
  const events = getSectionData('Events')
  const form = getSectionData('Form')
  const how = getSectionData('How')
  const landing = getSectionData('Landing')
  const navLinks = get(getListData('Nav'), '[0].links')
  const social = getSectionData('Social')
  const socialLinks = getListData('SocialLink')
  const metaDescription = get(
    landing,
    'metaDescription.childMarkdownRemark.rawMarkdownBody'
  )

  return (
    <Layout
      keywords={landing.keywords}
      metaDescription={metaDescription}
      navLinks={navLinks}
      siteTitle={landing.siteTitle}
      socialLinks={socialLinks}
    >
      <Landing
        {...landing}
        setActiveSection={handleActiveSection}
        setShowModal={setShowConnectModal}
      />
      <About {...about} setActiveSection={handleActiveSection} />
      <How {...how} setActiveSection={handleActiveSection} />
      <Connect
        {...connect}
        form={form}
        show={showConnectModal}
        setShowModal={setShowConnectModal}
        setActiveSection={handleActiveSection}
      />
      <Events {...events} setActiveSection={handleActiveSection} />
      <Bio {...bio} setActiveSection={handleActiveSection} />
      <Social {...social} setActiveSection={handleActiveSection}>
        <SocialLinks key="social" large list={socialLinks} white />
      </Social>
      <SocialLinks
        key="vertical"
        activeSection={activeSection}
        list={socialLinks}
        vertical
      />
      <ErrorBoundary label="Button">
        <ConnectButton
          activeSection={activeSection}
          setShowModal={setShowConnectModal}
        />
      </ErrorBoundary>
      <ErrorBoundary label="Scroll">
        <ScrollManager hash={hash} />
      </ErrorBoundary>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allContentfulLanding {
      edges {
        node {
          slug
          label
          buttonLabel
          siteTitle
          keywords
          metaDescription {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }

    allContentfulAbout {
      edges {
        node {
          id
          slug
          text {
            id
            headline {
              childMarkdownRemark {
                html
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }

    allContentfulHow {
      edges {
        node {
          id
          slug
          headline {
            childMarkdownRemark {
              html
            }
          }
          text {
            id
            headline {
              childMarkdownRemark {
                html
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }

    allContentfulConnect {
      edges {
        node {
          id
          slug
          buttonLabel
          text {
            id
            headline {
              childMarkdownRemark {
                html
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }

    allContentfulEvents {
      edges {
        node {
          id
          slug
          locationTitle
          eventsTitle
          events {
            location
            date
            id
            title
            eventType
            flyer {
              file {
                url
              }
            }
            url
            locationUrl
            body {
              childMarkdownRemark {
                html
              }
            }
          }
          locations {
            id
            headline {
              childMarkdownRemark {
                html
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }

    allContentfulBio {
      edges {
        node {
          id
          slug
          image {
            file {
              details {
                image {
                  width
                  height
                }
              }
            }
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          testimonialsTitle
          testimonials {
            id
            author
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          text {
            id
            headline {
              childMarkdownRemark {
                html
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }

    allContentfulNav {
      edges {
        node {
          links {
            id
            title
            slug
          }
        }
      }
    }

    allContentfulSocial {
      edges {
        node {
          slug
          socialLabel
          videoEnabled
          videosTitle {
            childMarkdownRemark {
              html
            }
          }
          videos {
            id
            title
            url
          }
          imagesTitle {
            childMarkdownRemark {
              html
            }
          }
          images {
            id
            title
            url
            image {
              file {
                details {
                  image {
                    width
                    height
                  }
                }
              }
              fluid(maxWidth: 1200) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }

    allContentfulForm {
      edges {
        node {
          successMessage {
            childMarkdownRemark {
              html
            }
          }
          successLabel
          emailLabel
          emailPlaceholder
          nameLabel
          namePlaceholder
          bodyPlaceholder
          buttonLabel
          text {
            id
            headline {
              childMarkdownRemark {
                html
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }

    allContentfulSocialLink {
      edges {
        node {
          id
          title
          url
          date
          imageWhite {
            file {
              url
              details {
                size
                image {
                  width
                  height
                }
              }
            }
          }
          image {
            file {
              url
              details {
                size
                image {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`
