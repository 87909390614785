import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Headline from './Headline'

const Headlines = ({ index }) => {
  return (
    <>
      <Headline
        key="movement"
        index={0}
        reset={index === 2}
        show={index === 0}
      />
      <Headline key="breath" index={1} reset={index === 0} show={index === 1} />
      <Headline
        key="meditation"
        index={2}
        reset={index === 1}
        show={index === 2}
      />
    </>
  )
}

Headlines.propTypes = {
  index: PropTypes.number,
}

export default memo(Headlines)
