import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import icon from '../assets/connect.svg'

const ConnectButton = ({ activeSection, setShowModal, ...props }) => {
  const [init, setInit] = useState(false)
  const show = !(activeSection === '/' || activeSection === 'social')

  useEffect(() => {
    setTimeout(() => setInit(true), 2000)
  })

  return init ? (
    <Container show={show} onClick={() => setShowModal(true)} {...props}>
      <Button>
        <img src={icon} alt="Connect" />
      </Button>
    </Container>
  ) : null
}

ConnectButton.propTypes = {
  activeSection: PropTypes.string,
  setShowModal: PropTypes.func,
}

export default memo(ConnectButton)

const Container = styled.div`
  position: fixed;
  right: 25px;
  bottom: 15px;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.7s ease-out;

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      pointer-events: all;
    `};
`

const Button = styled.button`
  ${({ hide, theme }) => css`
    padding: 0;
    border: 0;
    border-radius: 50%;
    outline: none;
    height: 40px;
    width: 40px;

    color: #fff;
    background-color: ${theme.color.coral};

    cursor: pointer;
    z-index: 9;
    transition: background-color 0.4s ease-out;

    &:hover {
      background-color: ${theme.color.blue};
      transition: background-color 0.4s ease-out;
    }
  `};
`
