import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { navigate } from 'gatsby'
import { throttle } from 'lodash'
// import scrollTo from '../../utils/scrollTo'

export class ScrollManager extends Component {
  static propTypes = {
    hash: PropTypes.string,
  }

  _timeout = null

  constructor(props) {
    super(props)

    this._handleScroll = throttle(this._onScroll, 300)

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this._handleScroll, false)
    }
  }

  componentDidUpdate(prevProps) {
    if (typeof window === 'undefined') return

    const { hash } = this.props
    if (!!hash && prevProps.hash !== hash) {
      this._scroll()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }

  _scroll = () => {
    const { hash } = this.props
    const block = hash === '#connect' ? 'center' : 'start'
    const id = hash.substr(1)
    const smooth = true
    //scrollTo(id, smooth, block)
  }

  _onScroll = () => {
    const { hash } = this.props
    const y = Math.max(window.pageYOffset, window.scrollY)

    if (!!hash && y === 0) {
      navigate('/')
    }
  }

  render() {
    return null
  }
}

export default ScrollManager
