import React from 'react'

import Text, { setFontSize, sizes } from './Text'

import { Formik, Form, Field, ErrorMessage } from 'formik'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import { SANS_SERIF } from '../../style/type'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactForm = ({
  bodyPlaceholder,
  buttonLabel,
  emailPlaceholder,
  emailLabel,
  nameLabel,
  namePlaceholder,
  showSuccess,
}) => {
  return (
    <Formik
      initialValues={{ email: '', name: '', message: '' }}
      validate={values => {
        let errors = {}

        if (!values.name) errors.name = 'Required'

        if (!values.email) {
          errors.email = 'Required'
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address'
        }

        // if (!values.message) errors.message = 'Required'

        return errors
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const body = encode({ 'form-name': 'contact', ...values })
        fetch('/?no-cache=1', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body,
        })
          .then(showSuccess)
          .then(resetForm)
          .catch(error => alert(error))

        setSubmitting(false)
      }}
    >
      {({ isSubmitting }) => (
        <StyledForm data-netlify="true" data-netlify-honeypot="bot">
          <input type="hidden" name="form-name" value="contact" />

          <Row>
            <label htmlFor="name">
              <Text semiBold size="sm">
                {nameLabel}
              </Text>
            </label>
            <Input type="text" name="name" placeholder={namePlaceholder} />
            <ErrorMessage name="name" component="span" />
          </Row>

          <Row>
            <label htmlFor="email">
              <Text semiBold size="sm">
                {emailLabel}
              </Text>
            </label>
            <Input type="email" name="email" placeholder={emailPlaceholder} />
            <ErrorMessage name="email" component="span" />
          </Row>

          <Row>
            <Field
              name="message"
              render={({ field }) => (
                <TextArea {...field} rows="7" placeholder={bodyPlaceholder} />
              )}
            />
            <ErrorMessage name="message" component="span" />
          </Row>
          <button type="submit" disabled={isSubmitting}>
            <Text size="sm" semiBold white center>
              {buttonLabel}
            </Text>
          </button>
        </StyledForm>
      )}
    </Formik>
  )
}

export default ContactForm

const StyledForm = styled(Form)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    input,
    textarea {
      color: ${theme.color.blueDark};
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 3px;
      font-family: ${SANS_SERIF};
      font-weight: 600;
      ${setFontSize(sizes.sm)};

      &::placeholder {
        color: #9da1bd;
      }
    }

    button {
      ${theme.mixin.button}
    }

    span {
      color: ${theme.color.coral};
      ${setFontSize(sizes.xs)};
      font-weight: 600;
      padding: ${modularScale(0)};
      pointer-events: none;
    }
  `};
`

const Input = styled(Field)`
  padding: 0 ${modularScale(-2)} 0 90px;
  min-height: 45px;
`

const TextArea = styled.textarea`
  padding: ${modularScale(1)} ${modularScale(1)};
`

const Row = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    margin-bottom: ${modularScale(0)};
    min-height: 45px;
    overflow: hidden;
    border-radius: 3px;

    label,
    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    label {
      display: flex;
      align-items: center;
      left: 0;
      background: #ececec;
      color: ${theme.color.blueDark};
      min-height: 45px;
      padding-left: 15px;
      width: 70px;
    }

    span {
      right: 0;
    }
  `};
`
