import React, { memo } from 'react'
import PropTypes from 'prop-types'

import ErrorBoundary from '../common/ErrorBoundary'
import FadeInView from '../common/FadeInView'
import FlickityContainer from '../common/Flickity'
import { Row, Col } from 'react-flexa'
import ReactHtmlParser from 'react-html-parser'
import Section from '../common/Section'
import Text from '../common/Text'
import TextBlockWithImage from '../bio/TextBlockWithImage'

import { animated, useSpring, config } from 'react-spring'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import mq from '../../style/mediaQueries'
import { get } from 'lodash'

import waves from '../../assets/waves-social.svg'

const Bio = ({
  image,
  setActiveSection,
  slug,
  testimonials,
  testimonialsTitle,
  text,
}) => {
  return (
    <>
      <ErrorBoundary label="Bio">
        <Section
          id={slug}
          backgroundColor="#fff"
          center
          verticalMargin
          setActiveSection={setActiveSection}
        >
          <Content>
            {!!text &&
              text.map((item, i) => {
                return (
                  <TextBlockWithImage
                    key={`tb${i}`}
                    last
                    image={image}
                    {...item}
                  />
                )
              })}

            <FadeInView>
              <TestimonialContainer>
                <Text.h2 center>{testimonialsTitle}</Text.h2>

                <FlickityContainer
                  dotColor="#7db2b5"
                  hidePrevNext
                  length={testimonials.length}
                  options={{
                    fade: true,
                    autoPlay: 3000,
                    pauseAutoPlayOnHover: true,
                  }}
                >
                  {testimonials.map(({ author, id, text }) => {
                    const bodyText = get(text, 'childMarkdownRemark.html')
                    return (
                      <TestimonialCard key={id}>
                        <Col
                          xs="hidden"
                          sm="hidden"
                          md={1}
                          lg={1}
                          xl={1}
                          xxl={1}
                        />
                        <TestimonialCenter
                          display="flex"
                          xs={12}
                          sm={12}
                          md={10}
                          lg={10}
                          xl={10}
                          xxl={10}
                        >
                          <Circle>
                            <Text center>{ReactHtmlParser(bodyText)}</Text>
                            <Author>
                              <Line />
                              <Text semiBold>{author}</Text>
                            </Author>
                          </Circle>
                        </TestimonialCenter>
                        <Col
                          xs="hidden"
                          sm="hidden"
                          md={1}
                          lg={1}
                          xl={1}
                          xxl={1}
                        />
                      </TestimonialCard>
                    )
                  })}
                </FlickityContainer>
              </TestimonialContainer>
            </FadeInView>
          </Content>
        </Section>
      </ErrorBoundary>
      <WavesContainer>
        <Waves
          src={waves}
          //style={slideInWaves}
          alt="Waves"
        />
      </WavesContainer>
    </>
  )
}

Bio.propTypes = {
  slug: PropTypes.string,
  text: PropTypes.array,
}

export default memo(Bio)

const Content = styled.div`
  ${({ theme: { layout } }) => css`
    width: calc(100% - ${layout.paddingSm}px);
    max-width: ${layout.maxWidth}px;
    padding: ${modularScale(4)} 0;
    min-height: 600px;

    ${mq.md`
        width: calc(100% - ${layout.padding * 2}px);
    `};
  `};
`

const TestimonialContainer = styled.div`
  position: relative;
  margin: ${modularScale(8)} 0 0;

  max-height: 460px;

  h2 {
    margin-bottom: ${modularScale(2)};
  }
`

const TestimonialCard = styled(Row)`
  width: 100vw;
`

const TestimonialCenter = styled(Col)`
  display: flex;
  justify-content: center;
`

const Author = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${modularScale(2)};
`

const Line = styled.div`
  opacity: 0.5;
  width: 30px;
  height: 2px;
  background-color: #7db2b5;
  margin-right: ${modularScale(-2)};
`

const Circle = styled.div`
  ${({ theme: { layout } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: calc(100vw - ${layout.paddingSm}px);
    max-width: 420px;
    max-height: 420px;
    padding: 40px;

    ${mq.md`
      background-color: #f2f2f2;
      height: calc(100vw - ${layout.paddingSm}px);
    `};
  `};
`

const WavesContainer = styled.div`
  overflow: hidden;
  max-width: 100vw;
  width: 100%;
  min-height: calc(100vw * (1098 / 1433));
  transform: translateY(2px);
`

const Waves = styled(animated.img)`
  width: 100%;
  height: auto;
  min-width: 450px;
`
