import React, { memo } from 'react'
import PropTypes from 'prop-types'

import ErrorBoundary from '../common/ErrorBoundary'
import FadeInView from '../common/FadeInView'
import { Row, Col } from 'react-flexa'
import Section from '../common/Section'
import TextBlock from '../common/TextBlock'
import Text from '../common/Text'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'

import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import mq from '../../style/mediaQueries'
import { get } from 'lodash'

export function transform(node, index) {
  const { type, name } = node
  if (type === 'tag' && (name === 'h2' || name === 'h3' || name === 'p')) {
    return convertNodeToElement({ ...node, name: 'h1' }, index, transform)
  }
}

const isOdd = num => num % 2

const Option1 = props => (
  <Row>
    <Col md="hidden" lg={2} xl={2} />
    <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={7}>
      <TextBlock {...props} />
    </Col>
  </Row>
)

const Option2 = props => (
  <Row>
    <Col sm="hidden" md={2} lg={5} xl={6} xxl={6.5} />
    <Col xs={12} sm={12} md={10} lg={6} xl={5} xxl={4}>
      <TextBlock {...props} />
    </Col>
  </Row>
)

const How = ({ headline, setActiveSection, slug, text }) => {
  const headlineText = get(headline, 'childMarkdownRemark.html')
  return (
    <ErrorBoundary label="How">
      <Section
        id={slug}
        backgroundColor="white"
        center
        setActiveSection={setActiveSection}
      >
        <Content>
          {!!headlineText && (
            <FadeInView>
              <Headline>
                <Text html>{ReactHtmlParser(headlineText, { transform })}</Text>
              </Headline>
            </FadeInView>
          )}

          {!!text &&
            text.map((item, i) => {
              const Option = isOdd(i) ? Option2 : Option1
              const last = i === text.length - 1
              return (
                <FadeInView key={item.id}>
                  <Option last={last} {...item} />
                </FadeInView>
              )
            })}
          <Option1 />
          <Option2 />
        </Content>
      </Section>
    </ErrorBoundary>
  )
}

How.propTypes = {
  slug: PropTypes.string,
  text: PropTypes.array,
}

export default memo(How)

const Content = styled.div`
  ${({ theme: { layout } }) => css`
    width: calc(100% - ${layout.paddingSm}px);
    max-width: ${layout.maxWidth}px;
    padding: ${modularScale(4)} 0;
    min-height: 600px;

    ${mq.md`
        width: calc(100% - ${layout.padding * 2}px);
    `};
  `};
`

const Headline = styled.div`
  //max-width: 1000px;
  margin-bottom: ${modularScale(6)};
`
