import React, { useEffect, useState, memo } from 'react'
import PropTypes from 'prop-types'

import Button from '../common/Button'
import ErrorBoundary from '../common/ErrorBoundary'
import Headlines from './Headlines'
import Section from '../common/Section'
import Text, { setFontSize, sizes } from '../common/Text'
import LiquidButton from '../common/LiquidButton'
import { Link } from 'gatsby'

import { timingFunctions } from 'polished'
import { useSpring, animated, config } from 'react-spring'
import styled from 'styled-components'
import { modularScale } from 'polished'
import mq from '../../style/mediaQueries'
import { useInterval } from 'react-powerhooks'
import { useInView } from 'react-intersection-observer'
import { device } from '../../style/theme'

import waves from '../../assets/waves-1.svg'

const duration = 4000

let intervalOn = false

const DelayButton = ({ label }) => {
  const fadeInButton = useSpring({
    transform: 'translateY(0px) scale(1)',
    from: { opacity: 0, transform: 'translateY(0px) scale(0.95)' },
    opacity: 1,
    config: {
      duration: 1000,
      ease: timingFunctions('easeOutQuad'),
    },
  })

  return (
    <animated.div style={fadeInButton}>
      {device.mobile ? <Button>{label}</Button> : <LiquidButton text={label} />}
    </animated.div>
  )
}

const Landing = ({ setActiveSection, title, buttonLabel, setShowModal }) => {
  const [index, set] = useState(0)
  const [showButton, setShowButton] = useState(false)
  const [ref, inView] = useInView({ threshold: 0.2 })
  const openModal = () => setShowModal(true)

  const fadeInTitle = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 100,
    config: {
      duration: 2000,
      ease: timingFunctions('easeInOutQuad'),
    },
  })

  const slideInTitle = useSpring({
    transform: 'translateY(0px) scale(1)',
    from: { transform: 'translateY(0px) scale(0.95)' },
    delay: 100,
    config: {
      duration: 700,
      ease: timingFunctions('easeOutExpo'),
    },
  })

  const slideInWaves = useSpring({
    opacity: 1,
    transform: 'translateY(0px)',
    from: { opacity: 0, transform: 'translateY(20px)' },
    delay: 800,
    config: {
      ...config.molasses,
    },
  })

  const { start, stop } = useInterval({
    duration: showButton ? duration / 2 : duration,
    startImmediate: false,
    callback: () => {
      const next = index + 1
      set(next > 2 ? 0 : next)
    },
  })

  useEffect(() => {
    if (inView && !intervalOn) {
      start()
      intervalOn = true
    } else if (!inView && intervalOn) {
      stop()
      intervalOn = false
    }

    if (!showButton) setTimeout(() => setShowButton(true), 1000)
  }, [inView, intervalOn])

  return (
    <ErrorBoundary label="landing">
      <Section setActiveSection={setActiveSection} verticalMargin={false}>
        <Content>
          <TextContainer>
            <animated.div style={{ ...fadeInTitle, ...slideInTitle }}>
              <Text.h2 lg="lg" center>
                {title}
              </Text.h2>
            </animated.div>
            <HeadlinesContainer ref={ref}>
              <Headlines index={index} />
            </HeadlinesContainer>
            {showButton && inView ? (
              <div onClick={openModal}>
                <DelayButton label={buttonLabel} />
              </div>
            ) : (
              <Spacer />
            )}
          </TextContainer>
        </Content>
      </Section>
      <WavesContainer>
        <Waves src={waves} style={slideInWaves} alt="Waves" />
      </WavesContainer>
    </ErrorBoundary>
  )
}

Landing.propTypes = {
  subTitle: PropTypes.array,
  title: PropTypes.string,
}

Landing.defaultProps = {
  subTitle: ['Movement', 'Breath', 'Meditation'],
  title: 'Feel good now through',
}

export default memo(Landing)

const Spacer = styled.div`
  height: 90px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50vh;
  margin-top: ${modularScale(4)};

  button {
    margin-top: 20px;
  }

  h2 {
    margin-bottom: 0;
    font-size: 3.95vw;

    ${mq.lg`
      ${setFontSize(sizes.lg)}
    `};
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20vw;
  min-height: 250px;
  max-height: 450px;

  ${mq.md`
    min-height: 400px;
  `};
`

const WavesContainer = styled.div`
  overflow: hidden;
  max-width: 100vw;
  width: 100%;
  min-height: calc(100vw * (921 / 1433));
`

const Waves = styled(animated.img)`
  width: 100%;
  height: auto;
  min-width: 450px;
`

const HeadlinesContainer = styled.div`
  position: relative;
  width: 80vw;
  height: 24vw;
  height: 90px;
  margin: 3vw 0 calc(3vw - 20px);

  ${mq.md`
    height: 90px;
    margin: 4.5vw 0 calc(4.5vw - 20px);
  `};

  ${mq.lg`
      margin: ${modularScale(4)} 0 calc(${modularScale(4)} - 20px);
    `};
`
