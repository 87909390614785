import React, { memo } from 'react'
import PropTypes from 'prop-types'

import ErrorBoundary from '../common/ErrorBoundary'
import FadeInView from '../common/FadeInView'
import { Row, Col } from 'react-flexa'
import Section from '../common/Section'
import TextBlock from '../common/TextBlock'
import { Parallax } from 'react-scroll-parallax'

import { breakpointsPx } from '../../style/theme'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import mq from '../../style/mediaQueries'
import { useMedia } from 'use-media'

const isOdd = num => num % 2

const Option1 = ({ isXL, ...props }) => (
  <Parallax y={[-50, 10]} disabled={!isXL}>
    <Row>
      <Col md="hidden" lg={1} xl={1} />
      <Col xs={12} sm={12} md={10} lg={6} xl={5} xxl={4}>
        <TextBlock {...props} />
      </Col>
    </Row>
  </Parallax>
)

const Option2 = ({ isXL, ...props }) => (
  <Parallax y={[-60, 30]} disabled={!isXL}>
    <Row>
      <Col xs="hidden" sm="hidden" md={2} lg={5} xl={6} xxl={6.5} />
      <Col xs={12} sm={12} md={10} lg={6} xl={5} xxl={4}>
        <TextBlock {...props} />
      </Col>
    </Row>
  </Parallax>
)

const About = ({ setActiveSection, slug, text }) => {
  const isXL = useMedia({ minWidth: breakpointsPx.xl })

  return (
    <ErrorBoundary label="About">
      <Section
        id={slug}
        backgroundColor="grey"
        center
        setActiveSection={setActiveSection}
        verticalMargin
      >
        <Content>
          {!!text &&
            text.map((item, i) => {
              const Option = isOdd(i) ? Option2 : Option1
              const last = i === text.length - 1
              return (
                <FadeInView key={item.id}>
                  <Option last={last} isXL={isXL} {...item} />
                </FadeInView>
              )
            })}
        </Content>
      </Section>
    </ErrorBoundary>
  )
}

About.propTypes = {
  setActiveSection: PropTypes.func,
  slug: PropTypes.string,
  text: PropTypes.array,
}

export default memo(About)

const Content = styled.div`
  ${({ theme: { layout } }) => css`
    width: calc(100% - ${layout.paddingSm}px);
    max-width: ${layout.maxWidth}px;
    padding: ${modularScale(4)} 0;
    min-height: 600px;

    ${mq.md`
        width: calc(100% - ${layout.padding * 2}px);
    `};
  `};
`
