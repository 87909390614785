import React, { memo } from 'react'
import PropTypes from 'prop-types'

import ErrorBoundary from '../common/ErrorBoundary'
import FadeInView from '../common/FadeInView'
import FlickityContainer from '../common/Flickity'
import Section from '../common/Section'
import Where from './Where'
import Text from '../common/Text'
import ReactHtmlParser from 'react-html-parser'

import { animated } from 'react-spring'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import { get } from 'lodash'
import mq from '../../style/mediaQueries'
import format from 'date-fns/format'

import waves from '../../assets/waves-events.svg'
import arrow from '../../assets/link-arrow.svg'

const Events = ({
  events,
  eventsTitle,
  locations,
  locationTitle,
  setActiveSection,
  slug,
}) => {
  return (
    <ErrorBoundary label="Events">
      <Section
        id={slug}
        backgroundColor="#139DAC"
        center
        setActiveSection={setActiveSection}
        verticalMargin
      >
        <Content>
          <FadeInView>
            <Where locations={locations}>{locationTitle}</Where>
          </FadeInView>
          <FadeInView>
            <EventsContainer>
              <Text.h2 size={18} md={22} lg="lg" white>
                {eventsTitle}
              </Text.h2>
              <Flick>
                <FlickityContainer length={events.length}>
                  {events &&
                    events.map(
                      ({
                        body,
                        date,
                        eventType,
                        flyer,
                        id,
                        location,
                        locationUrl,
                        title,
                        url,
                      }) => {
                        const bodyText = get(body, 'childMarkdownRemark.html')
                        const formatTitle = url ? (
                          <a href={url} target="_blank">
                            {title}
                          </a>
                        ) : (
                          title
                        )
                        const formatLocation = url ? (
                          <a href={locationUrl} target="_blank">
                            {location}
                          </a>
                        ) : (
                          location
                        )
                        const flyerUrl = get(flyer, 'file.url')
                        return (
                          <EventItem key={id}>
                            <Text size="sm" white semiBold>
                              {eventType}
                            </Text>
                            <Line />

                            <Headline
                              size={18}
                              md={22}
                              lg={22}
                              html
                              white
                              semiBold
                            >
                              {formatTitle}
                            </Headline>

                            {!!location && (
                              <EventLocation>
                                <Text size="sm" lg="md" html white>
                                  @ {formatLocation}
                                </Text>
                                <EventText size="sm" lg="md" html white>
                                  {format(date, 'M/D/YY - h:mma')}
                                </EventText>
                              </EventLocation>
                            )}

                            {!!flyerUrl && (
                              <EventText size="sm" lg="md" html white>
                                <a href={flyerUrl} target="_blank">
                                  View flyer
                                </a>
                              </EventText>
                            )}

                            {!!bodyText && (
                              <EventText size="sm" lg="md" html white>
                                {ReactHtmlParser(bodyText)}
                                {!!url && (
                                  <a href={url} target="_blank">
                                    More info
                                  </a>
                                )}
                              </EventText>
                            )}
                          </EventItem>
                        )
                      }
                    )}
                </FlickityContainer>
              </Flick>
            </EventsContainer>
          </FadeInView>
        </Content>
      </Section>
      <WavesContainer>
        <Waves
          src={waves}
          //style={slideInWaves}
          alt="Waves"
        />
      </WavesContainer>
    </ErrorBoundary>
  )
}

Events.propTypes = {
  locationTitle: PropTypes.string,
  slug: PropTypes.string,
  text: PropTypes.array,
}

export default memo(Events)

const Flick = styled.div`
  position: relative;
`

const Content = styled.div`
  ${({ theme: { layout } }) => css`
    width: calc(100% - ${layout.paddingSm}px);
    max-width: ${layout.maxWidth}px;
    padding: ${modularScale(4)} 0;
    min-height: 600px;

    ${mq.md`
        width: calc(100% - ${layout.padding * 2}px);
    `};

    h2 {
      margin-bottom: ${modularScale(1)};
    }
  `};
`

const EventsContainer = styled.div`
  padding-top: ${modularScale(6)};
  height: 100%;
`

const EventItem = styled.div`
  ${({ theme: { layout } }) => css`
    position: relative;
    background: rgba(135, 215, 225, 0.5);
    border-radius: 4px;
    padding: ${modularScale(1)};
    width: calc(100vw - ${layout.paddingSm}px);
    max-width: 420px;
    height: 320px;

    margin-right: ${modularScale(2)};
    margin-left: ${layout.paddingSm / 2}px;

    ${mq.md`
        margin-right: ${modularScale(4)};
    `};
  `};
`

const EventLocation = styled.div`
  margin-bottom: ${modularScale(1)};

  > div {
    margin-bottom: ${modularScale(-3)};
  }
`

const EventText = styled(Text)`
  img {
    max-width: 50px;
    max-height: 50px;
    object-fit: scale-down;
  }

  a {
    &:after {
      margin-left: 5px;
      content: url(${arrow});
      width: 12px;
      height: 12px;
    }
  }
`

const Headline = styled(Text)`
  margin-bottom: ${modularScale(0)};

  a {
    text-decoration: none;
  }
`

const Line = styled.div`
  position: relative;
  margin: ${modularScale(0)} 0 ${modularScale(2)};
  width: 100%;
  height: 1px;

  ${mq.lg`
    margin: ${modularScale(0)} 0 ${modularScale(2)};
  `};

  &:before {
    content: '';
    position: absolute;
    background-color: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
  }
`

const WavesContainer = styled.div`
  overflow: hidden;
  max-width: 100vw;
  width: 100%;
  min-height: calc(100vw * (964 / 1433));
  transform: translateY(-1px);
`

const Waves = styled(animated.img)`
  width: 100%;
  height: auto;
  min-width: 450px;
`
