import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Text from '../common/Text'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import { Row, Col } from 'react-flexa'

import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import mq from '../../style/mediaQueries'
import { get } from 'lodash'

function transform(node, index) {
  const { type, name } = node
  if (type === 'tag' && (name === 'h1' || name === 'h2' || name === 'p')) {
    return convertNodeToElement(
      { ...node, name: 'div', attribs: {} },
      index,
      transform
    )
  }
}

const Where = ({ children, locations }) => {
  console.log(locations)
  return (
    <Container>
      <Row>
        <Col xs="hidden" sm={1} md={2} lg={1} xl={1} xxl={1} />
        <Col xs={12} sm={11} md={10} lg={11} xl={11} xxl={11}>
          <Text.h2 size={18} md={22} lg="lg" white>
            {children}
          </Text.h2>
          <FlexRow>
            <Line />
            <Row>
              {locations &&
                locations.map(({ id, body, headline }) => {
                  const headlineText = get(headline, 'childMarkdownRemark.html')
                  const bodyText = get(body, 'childMarkdownRemark.html')
                  return (
                    <Item
                      key={id}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      xxl={6}
                    >
                      <Text
                        size={18}
                        sm="md"
                        md="md"
                        lg={22}
                        html
                        white
                        semiBold
                      >
                        {ReactHtmlParser(headlineText, { transform })}
                      </Text>
                      {!!bodyText && (
                        <Text size="sm" lg="md" html white>
                          {ReactHtmlParser(bodyText)}
                        </Text>
                      )}
                    </Item>
                  )
                })}
            </Row>
          </FlexRow>
        </Col>
      </Row>
    </Container>
  )
}

Where.propTypes = {
  body: PropTypes.object,
  children: PropTypes.node,
  locations: PropTypes.array,
  last: PropTypes.bool,
}

export default memo(Where)

const Container = styled.div`
  ${({ last }) =>
    !last &&
    css`
      margin-bottom: ${modularScale(6)};
    `};
`

const FlexRow = styled.div`
  display: flex;
  width: 100%;
`

const Item = styled(Col)`
  &:not(:last-child) {
    margin-bottom: ${modularScale(3)};
  }

  div {
    padding-right: ${modularScale(4)};

    &:first-child {
      margin-bottom: ${modularScale(-6)};
    }
  }

  ${mq.lg`
    margin-bottom: 0;

    &:not(:last-child) { 
        margin-bottom: 0;
    }

    &:nth-child(-n + 2) {
        margin-bottom: ${modularScale(4)};
    }
  `};
`

const Line = styled.div`
  position: relative;

  margin-right: 10vw;

  ${mq.lg`
    margin-right: ${modularScale(5)};
  `};

  &:before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.color.blueLight2};
    width: 3px;
    height: 100%;
  }
`
