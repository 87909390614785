import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import { get } from 'lodash'
import mq from '../../style/mediaQueries'

const Section = ({ children, id, setActiveSection, ...props }) => {
  const [ref, inView] = useInView({ threshold: 0.3 })

  useEffect(() => {
    if (inView) setActiveSection(id ? id : '/')
  })

  return (
    <Container ref={ref} id={id} {...props}>
      {children}
    </Container>
  )
}

Section.propTypes = {
  backgroundColor: PropTypes.string,
  center: PropTypes.bool,
  verticalMargin: PropTypes.bool,
}

Section.defaultProps = {
  backgroundColor: 'white',
  verticalMargin: true,
}

export default Section

const Container = styled.section`
  ${({
    backgroundColor,
    center,
    hidden,
    verticalMargin,
    noPaddingBottom,
    theme,
  }) => css`
    position: relative;
    width: 100%;

    background-color: ${get(theme.color, backgroundColor) || backgroundColor};

    ${hidden &&
      css`
        overflow: hidden;
      `};

    ${verticalMargin && theme.layout.verticalMargin};

    ${noPaddingBottom &&
      css`
        padding-bottom: 0;

        ${mq.lg`
          padding-bottom: 0;
        `};
      `}

    ${center &&
      css`
        display: flex;
        justify-content: center;
      `};
  `};
`
