import React from 'react'
import PropTypes from 'prop-types'

import Img from 'gatsby-image'
import ErrorBoundary from '../common/ErrorBoundary'
import FlickityContainer from '../common/Flickity'
import FadeInView from '../common/FadeInView'
import Logo from '../common/Logo'
import ReactHtmlParser from 'react-html-parser'
import Section from '../common/Section'
import Text from '../common/Text'
import ReactPlayer from 'react-player'
import { Row, Col } from 'react-flexa'

import { animated } from 'react-spring'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import mq from '../../style/mediaQueries'
import { get } from 'lodash'
import useLoadImages from '../../hooks/useLoadImages'

import waves from '../../assets/waves-end.svg'

const Social = ({
  children,
  images,
  imagesTitle,
  setActiveSection,
  socialLabel,
  slug,
  videoEnabled,
  videos,
  videosTitle,
}) => {
  const imagesText = get(imagesTitle, 'childMarkdownRemark.html')
  const videosText = get(videosTitle, 'childMarkdownRemark.html')
  const loaded = useLoadImages(images.map(item => item.image.fluid.src))

  return (
    <>
      <ErrorBoundary label="Social">
        <Section
          id={slug}
          backgroundColor="#00263E"
          center
          setActiveSection={setActiveSection}
          verticalMargin
          noPaddingBottom
        >
          <Content>
            <FadeInView>
              <Title html white>
                {ReactHtmlParser(imagesText)}
              </Title>
            </FadeInView>

            <FadeInView>
              <ImageContainer>
                <FlickityContainer key="img" loaded={loaded}>
                  {images &&
                    images.map(({ id, image, title, url }) => (
                      <ImageItem key={id} aspectRatio={image.fluid.aspectRatio}>
                        <a href={url} target="_blank">
                          <Img fluid={image.fluid} alt={title} />
                        </a>
                      </ImageItem>
                    ))}
                </FlickityContainer>
              </ImageContainer>
            </FadeInView>

            <VideoContainer>
              {videoEnabled && videos.length && (
                <>
                  <FadeInView>
                    <Title html white>
                      {ReactHtmlParser(videosText)}
                    </Title>
                  </FadeInView>
                  <FadeInView>
                    <FlickityContainer key="video">
                      {videos.map(({ id, url }) => (
                        <VideoItem key={id}>
                          <ReactPlayer
                            key={id}
                            height="100%"
                            width="100%"
                            url={url}
                            controls
                          />
                        </VideoItem>
                      ))}
                    </FlickityContainer>
                  </FadeInView>
                </>
              )}
            </VideoContainer>

            <FadeInView>
              <Row>
                <Col xs="hidden" xs="hidden" md={1} lg={1} xl={1} xxl={1} />
                <Col xs={12} sm={12} md={11} lg={11} xl={11} xxl={11}>
                  <SocailContainer>
                    <Text html white size="md" semiBold>
                      {socialLabel}
                    </Text>
                    {children}
                  </SocailContainer>
                </Col>
              </Row>
            </FadeInView>
          </Content>
        </Section>
        <End>
          <Logo width={112} height={34} color="#fff" />

          <WavesContainer>
            <Waves
              src={waves}
              //style={slideInWaves}
              alt="Waves"
            />
          </WavesContainer>
        </End>
      </ErrorBoundary>
    </>
  )
}

Social.propTypes = {
  images: PropTypes.array,
  imagesTitle: PropTypes.object,
  socialLabel: PropTypes.string,
  slug: PropTypes.string,
  videoEnabled: PropTypes.bool,
  videos: PropTypes.array,
  videosTitle: PropTypes.object,
}

export default Social

const Content = styled.div`
  ${({ theme: { layout } }) => css`
    width: calc(100% - ${layout.paddingSm}px);
    max-width: ${layout.maxWidth}px;
    padding: ${modularScale(4)} 0;
    padding-bottom: 0;
    min-height: 600px;

    ${mq.md`
        width: calc(100% - ${layout.padding * 2}px);
    `};
  `};
`

const Title = styled(Text)`
  max-width: 300px;
  margin-bottom: ${modularScale(2)};
`

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  > div:first-child {
    width: 100%;
  }
`

const ImageItem = styled.div`
  ${({ aspectRatio }) => css`
    width: calc(250px * ${aspectRatio});
    min-width: calc(250px * ${aspectRatio});
    overflow: hidden;

    margin-right: ${modularScale(2)};

    ${mq.md`
        width: calc(300px * ${aspectRatio});
        min-width: calc(300px * ${aspectRatio});

        margin-right: ${modularScale(3)};
      `};

    ${mq.md`
        width: calc(350px * ${aspectRatio});
        min-width: calc(350px * ${aspectRatio});

        margin-right: ${modularScale(3)};
      `};

    ${mq.lg`
        width: calc(450px * ${aspectRatio});
        min-width: calc(450px * ${aspectRatio});

        margin-right: ${modularScale(4)};
      `};

    ${mq.xl`
      width: calc(500px * ${aspectRatio});
        margin-right: ${modularScale(6)};
      `};

    a {
      width: 100%;
      height: 100%;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: auto;
    }
  `};
`

const VideoItem = styled.div`
  ${({ theme }) => css`
    width: 85vw;
    height: calc(85vw * 9 / 16);
    max-width: 800px;
    max-height: calc(800px * 9 / 16);

    margin-right: ${theme.layout.paddingSm / 4}px;

    ${mq.md`
        margin-right: ${modularScale(2)};
    `};

    ${mq.lg`
        margin-right: ${modularScale(4)};
    `};

    ${mq.xxl`
         margin-right: ${modularScale(6)};
    `};
  `};
`

const SocailContainer = styled.div`
  margin-top: ${modularScale(6)};
`

const VideoContainer = styled.div`
  position: relative;
  margin: ${modularScale(6)} 0 ${modularScale(8)};
`
const End = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    background-color: ${theme.color.blueDark};
    padding: ${modularScale(2)} 0;

    > svg {
      position: absolute;
      top: 50%;
      left: 0;
      width: 20vw;
      height: calc(20vw * (32 / 112));
      min-width: 112px;
      min-height: 32px;
      max-width: 150px;
      max-height: ${150 * (32 / 112)}px;
      transform: translate(calc(90vw - 100%), -40%);
    }
  `};
`

const WavesContainer = styled.div`
  ${({ theme }) => css`
    overflow: hidden;
    max-width: 100vw;
    width: 100%;
    background-color: ${theme.color.blueDark};
  `};
`

const Waves = styled(animated.img)`
  width: 100%;
  height: auto;
  min-width: 450px;
`
