import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Form from './Form'
import CloseIcon from '../../assets/CloseIcon'
import ModalBox from './ModalBoxForm'
import Portal from './Portal'
import TextBlock from './TextBlock'
import Text from './Text'
import ReactHtmlParser from 'react-html-parser'

import keyCodes from '../../constants/keyCodes'
import styled, { css, keyframes } from 'styled-components'
import { timingFunctions, modularScale } from 'polished'
import { isNull, get } from 'lodash'
import mq from '../../style/mediaQueries'

import waves from '../../assets/waves-form.svg'

class ModalForm extends Component {
  static propTypes = {
    actions: PropTypes.object,
    children: PropTypes.node,
    fullscreen: PropTypes.bool,
    onClose: PropTypes.func,
    onKeyDown: PropTypes.func,
    hideModal: PropTypes.func.isRequired,
    isMenu: PropTypes.bool,
    noScroll: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    small: PropTypes.bool,
    successMessage: PropTypes.object,
    successLabel: PropTypes.string,
    targetId: PropTypes.string,
    title: PropTypes.string,
  }

  state = {
    queuedToHide: false,
    messageSent: false,
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props
    if (!prevProps.show && show) this._addListeners(show)
  }

  componentWillUnmount() {
    this._addListeners(false)
    this._reset()
  }

  close = () => this._handleClose()

  _addListeners = (add = true) => {
    if (typeof window !== 'undefined') {
      if (add) window.addEventListener('keydown', this._onKeyDown, false)
      else window.removeEventListener('keydown', this._onKeyDown)
    }
  }

  _onKeyDown = e => {
    const { onKeyDown } = this.props
    const key = e.which || e.keyCode
    if (key === keyCodes.ESC) this._handleClose()
    if (onKeyDown) onKeyDown(key)
  }

  _handleClose = () => {
    const { hideModal, show } = this.props
    if (show) {
      this._reset(true)
      if (hideModal) hideModal()

      this._timeout = setTimeout(this._reset, TIME - 10)
    }
  }

  _reset = (queuedToHide = false) => {
    const { onClose } = this.props
    this.setState({ queuedToHide })

    if (!isNull(this._timeout)) {
      clearTimeout(this._timeout)
      this._timeout = null
      if (!queuedToHide && onClose) onClose()
    }
  }

  _showSuccess = () => {
    this.setState({ messageSent: true })
  }

  _hideSuccess = () => {
    this.setState({ messageSent: false })
  }

  render() {
    const {
      children,
      fullscreen,
      form,
      noScroll,
      show,
      successMessage,
      successLabel,
      targetId,
      title,
    } = this.props
    const { queuedToHide, messageSent } = this.state
    const showAll = show || queuedToHide
    const successText = get(form.successMessage, 'childMarkdownRemark.html')

    return showAll ? (
      <Portal id={targetId}>
        <Container hide={!show.toString()}>
          {!fullscreen && <Bg onClick={this._handleClose} />}
          <ModalBox hide={!show} fullscreen={fullscreen}>
            <Card>
              <Img src={waves} alt="Waves" />
              <Top>
                <CloseButton onClick={this._handleClose}>
                  <CloseIcon />
                </CloseButton>
              </Top>
              <Content
                noScroll={noScroll}
                hasTitle={title}
                hide={!show || !!messageSent}
                fullscreen={fullscreen}
              >
                <TextBlock hideLine white {...form.text} />
                <Form {...form} showSuccess={this._showSuccess} />

                {children}
              </Content>
              <Success show={!!messageSent}>
                {!!successText && (
                  <Text white html center>
                    {ReactHtmlParser(successText)}
                  </Text>
                )}
                <button onClick={this._hideSuccess}>{form.successLabel}</button>
              </Success>
            </Card>
          </ModalBox>
        </Container>
      </Portal>
    ) : null
  }
}

const TIME = 400

export default ModalForm

export const fadeIn = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        animation-delay: 0;
    }
`

export const fadeInWithDelay = keyframes`
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        animation-delay: 0;
    }
`

export const fadeAndScaleIn = keyframes`
    0% {
        height: 0;
    }

    100% {
        height: 100%;
        animation-delay: 0;
    }
`

export const fadeOutWithDelay = keyframes`
    0% {
        opacity: 1;
    }

     50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        animation-delay: 0;
    }
`

const transitionIn = css`
  animation: ${fadeIn} ${TIME / 1000}s ${timingFunctions('easeOutQuad')};
`

const transitionOut = css`
  pointer-events: none;
  opacity: 0;
  transition: opacity ${TIME / 1000}s ${timingFunctions('easeInQuad')};
`

const PAD_V = 50

const Success = styled.div`
  ${({ show, theme }) => css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 60px);
    max-width: 500px;
    z-index: 9999;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-out;

    h1,
    h2,
    h3 {
      margin-bottom: ${modularScale(-2)};
    }

    button {
      ${theme.mixin.button};
      margin-top: ${modularScale(2)};
    }

    ${show &&
      css`
        opacity: 1;
        pointer-events: all;
        transition: opacity 0.7s ease-out;
      `};
  `};
`

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ hide }) => (hide ? transitionOut : transitionIn)};
  z-index: 99999999999999;

  ${mq.md`
     align-items: center;
  `};
`

const Card = styled.div`
  position: relative;
  width: 100%;
  max-width: 945px;
  background-color: ${({ theme }) => theme.color.blueDark};
  border-radius: 5px;
  box-shadow: 4px 4px 10px 10px rgba(0, 0, 0, 0.1);

  ${mq.md`
    width: calc(100% - 30px);
  `};

  ${mq.lg`
    width: calc(100% - 60px);
  `};
`

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  ${transitionIn};
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(50% - ${PAD_V}px);
  padding: ${modularScale(1)} ${modularScale(2)};
  border-radius: 5px;
  transition: opacity 0.7s ease-out;

  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease-out;
    `};

  > div,
  > form {
    width: 100%;
    margin-bottom: ${modularScale(4)};

    ${mq.md`
      width: 50%;
      margin-bottom: ${modularScale(2)};

       > div {
        margin-right: ${modularScale(2)};
      }

      > form {
        margin-left: ${modularScale(2)};
      }
    `};
  }

  ${mq.md`
    padding: ${modularScale(2)} ${modularScale(3)};
    flex-direction: row;
  `};

  ${mq.lg`
    padding: ${modularScale(3)} ${modularScale(5)};
    flex-direction: row;
  `};

  ${mq.xl`
    padding: ${modularScale(3)} ${modularScale(6)};
  `};

  ${({ fullscreen, hasTitle, noScroll }) => css`
    margin-top: ${hasTitle ? 90 : PAD_V}px;

    ${fullscreen &&
      css`
        height: calc(100vh - ${PAD_V}px);
        max-height: calc(100vh - ${PAD_V}px);
        max-width: 100%;
      `};

    ${!noScroll &&
      css`
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      `};
  `};
`

const Top = styled.div`
  position: absolute;
  top: 0;
  padding: 8px 0;
  right: 15px;
  z-index: 10;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 2px 5px;
  cursor: pointer;
  transform: translateX(8px);
  transition: opacity 0.3s ease-in;

  &:hover {
    opacity: 0.5;
    transition: opacity 0.3s ease-out;
  }
`

export const ModalPad = styled.div`
  position: relative;
`
