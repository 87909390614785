import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { useSpring, animated } from 'react-spring'
import { timingFunctions } from 'polished'
import { useInView } from 'react-intersection-observer'

const FadeInView = ({ children }) => {
  const [ref, inView] = useInView({ threshold: 0.2, triggerOnce: false })
  const fadeIn = useSpring({
    from: { opacity: 0 },
    opacity: inView ? 1 : 0,
    delay: 100,
    config: {
      duration: 1000,
      ease: timingFunctions('easeOutQuad'),
    },
  })

  return (
    <animated.div ref={ref} style={fadeIn}>
      {children}
    </animated.div>
  )
}

FadeInView.propTypes = {
  children: PropTypes.node,
}

export default memo(FadeInView)
